<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button
            variant="primary"
            @click="searchTable"
          >
            <span class="text-nowrap">Search</span>
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <table
            id="itemList"
            class="table b-table table-striped table-hover table-bordered table-responsive"
          >
            <thead role="rowgroup">
              <tr>
                <th
                  v-for="(col, i) in columns"
                  :key="i"
                >
                  {{ col.replace(/_/g, ' ') }}
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                v-if="rows.length < 0"
                role="row"
                class="b-table-empty-row"
              >
                <td
                  colspan="10"
                  role="cell"
                  class=""
                >
                  <div
                    role="alert"
                    aria-live="polite"
                  >
                    <div class="text-center my-2">
                      No matching records found
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(row, idx) in rows"
                :key="idx"
                role="row"
              >
                <td
                  v-for="(col, index) in columns"
                  :key="index"
                >
                  {{ row[col] }}
                  <template v-if="col === 'actions'">
                    <span class="mx-1">
                      <feather-icon icon="EditIcon" />
                    </span>
                    <span>
                      <feather-icon icon="TrashIcon" />
                    </span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              align="right"
              first-number
              last-number
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChanged"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import axios from 'axios'

export default {
  props: {
    apiUrl: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      rows: [],
      columns: [],
      totalRows: null,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
    }
  },
  created() {
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    async getAllUsers() {
      const response = await axios.get('http://localhost:3000/users')
      this.rows = response.data.users
      this.totalRows = response.data.users.length * 2
      this.columns = response.data.columns
    },
    onPageChanged(page) {
      const promis = axios.get(`${this.apiUrl}?_page=${page}`)
      return promis.then(res => {
        const items = res.data
        this.rows = res.data
        this.totalRows = 50
        this.posts = items
        return items
      // eslint-disable-next-line no-unused-vars
      })
    },
    async searchTable() {
      const response = await axios.get(`http://localhost:3000/users_paginate?q=${this.searchQuery}`)
      this.rows = response.data
      this.totalRows = response.data.length
    },
  },
}
</script>

<style>

</style>
